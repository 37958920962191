/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserType {
    PERSON = 'Person',
    SYSTEM = 'System',
    DEVICE = 'Device',
    DATA_PROCESSOR = 'DataProcessor',
}
