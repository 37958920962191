/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessTokenViewModel } from '../models/AccessTokenViewModel';
import type { ExternalLoginConfirmationRequest } from '../models/ExternalLoginConfirmationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns AccessTokenViewModel Success
     * @throws ApiError
     */
    public checkExternalLogin(
requestBody?: ExternalLoginConfirmationRequest,
): CancelablePromise<AccessTokenViewModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/account/check-external-login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }

}
