import { Reducer } from 'redux';

export type ErrorState = {
    has403Error: boolean;
    has404Error: boolean;
};

export const ErrorDefaultState: ErrorState = {
    has403Error: false,
    has404Error: false,
};

export interface On403Action {
    type: 'ON_403_FORBIDDEN_RECVD';
};

export interface Clear403Action {
    type: 'CLEAR_403_FORBIDDEN_ERROR';
};

export interface On404Action {
    type: 'ON_404_NOT_FOUND_RECVD';
};

export interface Clear404Action {
    type: 'CLEAR_404_NOT_FOUND_ERROR';
};

type Actions = On403Action | Clear403Action | On404Action | Clear404Action;


export const error: Reducer<ErrorState> = (state: ErrorState | undefined, action: Actions): ErrorState => {
    if (state === undefined) {
        return ErrorDefaultState;
    }

    switch (action.type) {
        case 'ON_403_FORBIDDEN_RECVD':
            return Object.assign({}, state, {
                has403Error: true
            });
        case 'CLEAR_403_FORBIDDEN_ERROR':
            return Object.assign({}, state, {
                has403Error: false
            });
        case 'ON_404_NOT_FOUND_RECVD':
            return Object.assign({}, state, {
                has404Error: true
            });
        case 'CLEAR_404_NOT_FOUND_ERROR':
            return Object.assign({}, state, {
                has404Error: false
            });
        default:
            return state;
    }
}
