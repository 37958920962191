import api from '../api';
import { reduxStore } from '../app/configureStore';
import requestUtils from '../utils/requestUtils';

class FirmwareActions {
    clear() {
        reduxStore.dispatch({ type: 'ON_CLEAR_FIRMWARE_LIST' });
    }

    getList() {
        return requestUtils.processRequest(api.firmware.getList(), 'ON_GET_FIRMWARE_LIST');
    }

    getUrl(id: number) {
        return requestUtils.processRequest(api.firmware.getUrl(id), 'ON_GET_FIRMWARE_URL');
    }

    clearUrl() {
        reduxStore.dispatch({ type: 'ON_CLEAR_FIRMWARE_URL' });
    }

    create(version: string, description: string | null, file: Blob) {
        return requestUtils.processRequest(api.firmware.insert({ Version: version, Description: description ?? undefined, File: file }), 'ON_CREATE_FIRMWARE');
    }

    delete(id: number) {
        return requestUtils.simpleRequest(api.firmware.delete(id));
    }
};

const actions = new FirmwareActions();

export default actions;
