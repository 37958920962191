import { Reducer } from 'redux';
import { BootstrapViewModel } from '../api/models/BootstrapViewModel';

export type BootstrapState = BootstrapViewModel;

export const BootstrapDefaultState: BootstrapState = {
    version: null
};

export interface OnBootstrapAction {
    type: 'ON_BOOTSTRAP';
    result: BootstrapViewModel;
}

export interface OnClearBootstrapAction {
    type: 'ON_CLEAR_BOOTSTRAP';
}

type Actions = OnBootstrapAction | OnClearBootstrapAction;

export const bootstrap: Reducer<BootstrapState, Actions> = (state: BootstrapState | undefined, action: Actions): BootstrapState => {
    if (state === undefined) {
        return BootstrapDefaultState;
    }

    switch (action.type) {
        case 'ON_BOOTSTRAP':
            return Object.assign({}, state, action.result);
        case 'ON_CLEAR_BOOTSTRAP':
            return BootstrapDefaultState;
        default:
            return state;
    }
}

