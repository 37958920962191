import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Component } from 'react';
import { connect } from 'react-redux';
import NotifierActions from '../actions/notifierActions';
import { ApplicationState } from './configureStore';
import { Notification } from '../reducers/notifier';

type NotifierProps = {
    notifications: Notification[];
}

type FullProps = NotifierProps & WithSnackbarProps;

class Notifier extends Component<FullProps> {
    displayed: number[] = [];

    storeDisplayed = (id: number) => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate(newProps: FullProps) {
        const { notifications: newSnacks } = newProps;
        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            if (notExists) continue;
            notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;

        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(notification.key)) return;
            // Display snackbar using notistack
            this.props.enqueueSnackbar(notification.message, notification.options);
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(notification.key);
            // Dispatch action to remove snackbar from redux store
            NotifierActions.removeMessage(notification.key);
        });
    }

    render() {
        return null;
    }
}


const mapStateToProps = (state: ApplicationState) => {
    const { notifier } = state;
    return {
        notifications: notifier?.notifications ?? []
    };
};

export default connect(
    mapStateToProps,
)(withSnackbar(Notifier));
