import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import * as React from 'react';
import BootstrapActions from '../actions/bootstrapActions';
import LoginActions from '../actions/loginActions';
import api from '../api';
import { ExternalLoginConfirmationRequest } from '../api/models/ExternalLoginConfirmationRequest';
import config from '../utils/config';
import i18nUtils from '../utils/i18nUtils';
import './login.css';

const _i = i18nUtils.translationFactory('Login');

export default class Login extends React.Component {

    render() {
        return (
            <div className="login-base">
                <div className="login-items">
                    <div className="login-title">{_i('Signin')}</div>
                    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
                        <GoogleLogin onSuccess={this.googleResponse} onError={this.onFailure} />
                    </GoogleOAuthProvider>
                </div>
            </div>
        );
    }

    onFailure = () => {
        alert(_i('Failed'));
    };

    googleResponse = (response: CredentialResponse) => {
        if (!response)
            return;

        const loginInfo: ExternalLoginConfirmationRequest = {
            loginProvider: 'Google',
            accessToken: response.credential,
        };

        api.accounts.checkExternalLogin(loginInfo)
            .then((response) => {
                if (response.accessToken) {
                    BootstrapActions.clear();
                    LoginActions.loggedIn(response.accessToken);
                }
            });
    };
}
