import { reduxStore } from '../app/configureStore';

const DefaultOptions = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    autoHideDuration: 5000,
    style: { whiteSpace: 'pre-line' }
}

class NotifierActions {
    addError = (message: string, options: any = null) => {
        this.addMessage(message, Object.assign({}, options, { variant: 'error' }))
    }

    addWarning = (message: string, options: any = null) => {
        this.addMessage(message, Object.assign({}, options, { variant: 'warning' }))
    }

    addInfo = (message: string, options: any = null) => {
        this.addMessage(message, Object.assign({}, options, { variant: 'info' }))
    }

    addSuccess = (message: string, options: any = null) => {
        this.addMessage(message, Object.assign({}, options, { variant: 'success' }))
    }

    addMessage = (message: string, options: any = null) => {
        reduxStore.dispatch({
            type: 'ADD_MESSAGE',
            notification: {
                key: new Date().getTime() + Math.random(),
                message,
                options: Object.assign({}, DefaultOptions, options)
            }
        });
    }

    removeMessage = (key: number) => {
        reduxStore.dispatch({
            type: 'REMOVE_MESSAGE',
            key
        });
    }
};

let actions = new NotifierActions();

export default actions;
