import { Reducer } from 'redux';

export type LoginState = {
    accessToken: string | null;
};

export const LoginDefaultState: LoginState = {
    accessToken: null,
};

export interface OnLoggedInAction {
    type: 'ON_LOGGEDIN';
    accessToken: string;
};

export interface OnLogOutAction {
    type: 'ON_LOGOUT';
};

type Actions = OnLoggedInAction | OnLogOutAction;

export const login: Reducer<LoginState> = (state: LoginState | undefined, action: Actions): LoginState => {
    if (state === undefined) {
        return LoginDefaultState;
    }

    switch (action.type) {
        case 'ON_LOGOUT':
            return Object.assign({}, state, { accessToken: null });
        case 'ON_LOGGEDIN':
            return Object.assign({}, state, { accessToken: action.accessToken });
        default:
            return state;
    }
}
