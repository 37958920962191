import api from '../api';
import { TelemetrySessionStatus } from '../api/models/TelemetrySessionStatus';
import { reduxStore } from '../app/configureStore';
import requestUtils from '../utils/requestUtils';

class SessionActions {
    clear() {
        reduxStore.dispatch({ type: 'ON_CLEAR_SESSION_LIST' });
    }

    getList(statuses?: TelemetrySessionStatus[] | undefined, serialNumber?: string, pageSize?: number, pageNumber?: number) {
        reduxStore.dispatch({ type: 'ON_SET_REQUESTED_SESSION_LIST_DATA', pageNumber: null });
        return requestUtils.processRequest(
            api.session.getSessions(
                statuses,
                serialNumber,
                pageSize,
                pageNumber
            ), 'ON_GET_SESSION_LIST', { pageSize: pageSize ?? 10, pageNumber: pageNumber ?? 0 });
    }

    delete(id: number) {
        return requestUtils.simpleRequest(api.session.deleteSession(id));
    }

    deleteAll() {
        return requestUtils.simpleRequest(api.session.deleteAllSessions());
    }

    resetSessionStatus(id: number) {
        return requestUtils.simpleRequest(api.session.resetSessionStatus(id));
    }
};

const actions = new SessionActions();

export default actions;
