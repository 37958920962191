import { Reducer } from 'redux';

export type Notification = {
    key: number;
    message: string;
    options: any;
}

export type NotifierState = {
    notifications: Notification[];
};

export const NotifierDefaultState: NotifierState = {
    notifications: [],
};

export interface AddMessageAction {
    type: 'ADD_MESSAGE';
    notification: Notification;
};

export interface RemoveMessageAction {
    type: 'REMOVE_MESSAGE';
    key: number;
};

type Actions = AddMessageAction | RemoveMessageAction;

export const notifier: Reducer<NotifierState, Actions> = (state: NotifierState | undefined, action: Actions): NotifierState => {
    if (state === undefined) {
        return NotifierDefaultState;
    }

    switch (action.type) {
        case 'ADD_MESSAGE':
            return {
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification,
                    },
                ],
            };

        case 'REMOVE_MESSAGE':
            return {
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        default:
            return state;
    }
}
