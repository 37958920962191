import { Button, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import FirmwareActions from '../actions/firmwareActions';
import { ApplicationState } from '../app/configureStore';
import { GlyphIcon } from '../utils/GlyphIcon';
import i18nUtils from '../utils/i18nUtils';
import './newfirmware.css';

const _i = i18nUtils.translationFactory('Firmwares.New');

type NewFirmwareProps = {
    onAddItem: () => void;
}

type State = {
    version: string;
    description: string;
    file: Blob | undefined;
    disableAddButton: boolean;
};

export class NewFirmware extends React.Component<NewFirmwareProps, State> {
    constructor(props: NewFirmwareProps) {
        super(props);

        this.state = {
            version: '',
            description: '',
            file: undefined,
            disableAddButton: false,
        }
    }

    componentDidMount() {
        this.setState({ disableAddButton: false });
    }

    render() {
        const { version, description, file, disableAddButton } = this.state;

        return (
            <div>
                <div className="drawer-title-box">
                    <div className="drawer-title">{_i('NewFirmware')}</div>
                </div>

                <div style={{ margin: '10px' }}>
                    <input
                        accept=".shs"
                        className="input-none"
                        id="upload-button"
                        onChange={this.handleFile}
                        type="file"
                    />
                    <label htmlFor="upload-button">
                        <Button color="primary" component="span" variant="outlined">
                            <GlyphIcon className='glyphicon-upload' />{_i('UploadFirmwareImage')}
                        </Button>
                        <div>{file?.name}</div>
                    </label>

                    <div>
                        <TextField variant='outlined' size='small' defaultValue={version ?? ''} label={_i('VersionTitle')} onChange={this.setVersionText} />
                    </div>
                    <div>
                        <div className="drawer-title">{_i('DescriptionTitle')}</div>
                    </div>
                    <div>
                        <Textarea defaultValue={description ?? ''} label={_i('DescriptionTitle')} onChange={this.setDescriptionText} className="description-text" />
                    </div>
                    <div>
                        <Button onClick={() => this.request()} disabled={disableAddButton || !file || !version} variant="outlined"><GlyphIcon className='glyphicon-plus' />{_i('AddFirmware')}</Button>
                    </div>
                </div>
            </div>
        );
    }

    setVersionText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ version: e.target.value });
    }

    setDescriptionText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ description: e.target.value });
    }

    handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files)
            return;

        this.setState({ file: e.target.files[0] });
    };

    request = () => {
        const { version, description, file } = this.state;
        const { onAddItem } = this.props;

        if (!file)
            return;

        this.setState({ disableAddButton: true });

        FirmwareActions.create(version, description, file)
            .then(() => {
                onAddItem();
                this.setState({ disableAddButton: false });
            });
    }


}

const mapStateToProps = (state: ApplicationState) => {
    const { } = state;
    return {
    };
};

export default connect(mapStateToProps)(NewFirmware);