/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccountsService } from './services/AccountsService';
import { BootstrapService } from './services/BootstrapService';
import { ClientService } from './services/ClientService';
import { FirmwareService } from './services/FirmwareService';
import { SessionService } from './services/SessionService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BlackburnClient {

    public readonly accounts: AccountsService;
    public readonly bootstrap: BootstrapService;
    public readonly client: ClientService;
    public readonly firmware: FirmwareService;
    public readonly session: SessionService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accounts = new AccountsService(this.request);
        this.bootstrap = new BootstrapService(this.request);
        this.client = new ClientService(this.request);
        this.firmware = new FirmwareService(this.request);
        this.session = new SessionService(this.request);
    }
}
