import { Reducer } from 'redux';
import { SessionViewModel } from '../api/models/SessionViewModel';

export type SessionState = {
    list?: SessionViewModel[] | null;
    pageNumber?: number | null;
};

export const SessionDefaultState: SessionState = {
    list: null,
    pageNumber: 0,
};

export interface OnSessionGetListAction {
    type: 'ON_GET_SESSION_LIST';
    result: SessionViewModel[];
    pageNumber: number;
}

export interface OnClearSessionAction {
    type: 'ON_CLEAR_SESSION_LIST';
}

export interface OnSetRequestedSessionDataAction {
    type: 'ON_SET_REQUESTED_SESSION_LIST_DATA';
    pageNumber: number | null;
}

type Actions = OnSessionGetListAction | OnClearSessionAction | OnSetRequestedSessionDataAction;

export const session: Reducer<SessionState, Actions> = (state: SessionState | undefined, action: Actions): SessionState => {
    if (state === undefined) {
        return SessionDefaultState;
    }

    switch (action.type) {
        case 'ON_GET_SESSION_LIST':
            return Object.assign({}, state, { list: state.list ? [...state.list, ...action.result] : action.result, pageNumber: action.pageNumber });
        case 'ON_CLEAR_SESSION_LIST':
            return Object.assign({}, state, { list: null, pageNumber: null });
        case 'ON_SET_REQUESTED_SESSION_LIST_DATA':
            return Object.assign({}, state, { pageNumber: action.pageNumber });
        default:
            return state;
    }
}

