/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FirmwareViewModel } from '../models/FirmwareViewModel';
import type { StringViewModel } from '../models/StringViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FirmwareService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FirmwareViewModel Success
     * @throws ApiError
     */
    public getList(): CancelablePromise<Array<FirmwareViewModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/firmware',
        });
    }

    /**
     * @param formData 
     * @returns FirmwareViewModel Success
     * @throws ApiError
     */
    public insert(
formData?: {
Version: string;
Description?: string;
File?: Blob;
},
): CancelablePromise<FirmwareViewModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/firmware',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @returns StringViewModel Success
     * @throws ApiError
     */
    public getUrl(
id: number,
): CancelablePromise<StringViewModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/firmware/{id}/url',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public delete(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/firmware/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
