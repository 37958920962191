import * as React from 'react';
import { Container } from 'react-bootstrap';
import NavMenu from './NavMenu';
import './layout.css'

export default class Layout extends React.PureComponent<{ children?: React.ReactNode }, {}> {
    public render() {
        return (
            <React.Fragment>
                <NavMenu />
                <Container className='content-view'>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}