import api from '../api';
import { ClientIdViewModel } from '../api/models/ClientIdViewModel';
import { reduxStore } from '../app/configureStore';
import requestUtils from '../utils/requestUtils';

class ClientActions {
    clear() {
        reduxStore.dispatch({ type: 'ON_CLEAR_CLIENT_LIST' });
    }

    getList(pageSize: number, pageNumber: number) {
        return requestUtils.processRequest(api.client.getClients(pageSize, pageNumber), 'ON_GET_CLIENT_LIST');
    }

    create(client: ClientIdViewModel) {
        return requestUtils.processRequest(api.client.addClient(client), 'ON_CREATE_CLIENT');
    }

    delete(id: number) {
        return requestUtils.simpleRequest(api.client.removeClient(id));
    }

    generateToken(id: number) {
        return requestUtils.processRequest(api.client.getTokenForProcessor(id), 'ON_GET_CLIENT_TOKEN');
    }
};

const actions = new ClientActions();

export default actions;
