import * as React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import i18nUtils from '../utils/i18nUtils';
import './NavMenu.css';
import UserPanel from './UserPanel';

const _i = i18nUtils.translationFactory('Menu');

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow nav-menu">
                    <Container>
                        <Navbar.Brand href="/">{_i('Title')}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2" />
                        <Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" id="basic-navbar-nav">
                            <ul className="navbar-nav flex-grow">
                                <UserPanel />
                            </ul>
                            <ul className="navbar-nav flex-grow">
                                <Nav>
                                    <Nav.Item>
                                        <LinkContainer to={'/'}>
                                            <Nav.Link className="text-dark">{_i('Client')}</Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <LinkContainer to={'/session'}>
                                            <Nav.Link className="text-dark">{_i('Session')}</Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <LinkContainer to={'/about'}>
                                            <Nav.Link className="text-dark">{_i('About')}</Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                </Nav>
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
