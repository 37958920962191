import i18nMessagesStore from '../data/i18nMessagesStore';

export function getMessage(path: string, optionalMessages: object | null = null) {
    const messages = optionalMessages || i18nMessagesStore.getMessages();
    const pathParts = path.split('.');
    let message;

    try {
        message = pathParts.reduce(function (obj: object, pathPart: string) {
            return (obj as { [key: string]: object })[pathPart];
        }, messages);
    } finally {
        if (message === undefined) {
            message = pathParts[pathParts.length - 1];
            //_logger.warn(`Unable to find message '${path}', using '${message}' instead.`);
        }
    }

    return String(message);
}

export function translationFactory(namespace: string) {
    return (key: string, optionalMessages: object | null = null) => {
        if (optionalMessages !== null) {
            return getMessage(namespace + '.' + key, optionalMessages);
        } else {
            return getMessage(namespace + '.' + key);
        }
    };
}

export default { getMessage, translationFactory };
