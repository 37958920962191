import { Spin } from 'antd';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

type LoadMoreTriggerProps = {
    canLoadMore: boolean;
    active: boolean;
    className?: string;
    containment?: any;
    delayedCall?: boolean;
    intervalDelay?: number;
    offset?: {
        top?: number;
        left?: number;
        bottom?: number;
        right?: number;
    },
    onChange?: (isVisible: boolean) => void,
    partialVisibility?: boolean,
    showLoader: boolean,
    children?: React.ReactNode;
};

type State = {
    isVisible: boolean,
}

class LoadMoreTrigger extends React.Component<LoadMoreTriggerProps, State> {
    node: any;
    interval: any;

    public static defaultProps = {
        active: true,
        containment: null,
        delayedCall: false,
        intervalDelay: 100,
        partialVisibility: false,
        showLoader: true,
    };

    constructor(props: LoadMoreTriggerProps) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.startWatching();
    }

    componentWillUnmount() {
        this.stopWatching();
    }

    getInitialState = () => {
        return {
            isVisible: false,
        };
    }

    startWatching = () => {
        if (this.interval) {
            return;
        }

        this.interval = setInterval(this.check, this.props.intervalDelay ?? 1000);

        // if dont need delayed call, check on load ( before the first interval fires )
        !this.props.delayedCall && this.check();
    }

    stopWatching = () => {
        if (this.interval) {
            this.interval = clearInterval(this.interval);
        }
    }

    check = () => {
        const el = ReactDOM.findDOMNode(this) as any;
        let rect;
        let containmentRect;

        // if the component has rendered to null, dont update visibility
        if (!el) {
            return this.state;
        }

        rect = el.getBoundingClientRect();

        if (this.props.containment) {
            containmentRect = this.props.containment.getBoundingClientRect();
        } else {
            containmentRect = {
                top: 0,
                left: 0,
                bottom:
                    window.innerHeight || document.documentElement.clientHeight,
                right: window.innerWidth || document.documentElement.clientWidth
            };
        }

        // Check if visibility is wanted via offset?
        const offset = this.props.offset || {};
        containmentRect.top += offset.top || 0;
        containmentRect.left += offset.left || 0;
        containmentRect.bottom -= offset.bottom || 0;
        containmentRect.right -= offset.right || 0;

        let isVisible = false;
        if (this.props.partialVisibility) {
            isVisible = !(
                rect.right < containmentRect.left ||
                rect.left > containmentRect.right ||
                rect.bottom < containmentRect.top ||
                rect.top > containmentRect.bottom
            );
        } else {
            isVisible =
                rect.top >= containmentRect.top &&
                rect.left >= containmentRect.left &&
                rect.bottom <= containmentRect.bottom &&
                rect.right <= containmentRect.right;
        }

        // notify the parent when the value changed
        if (this.state.isVisible !== isVisible) {
            this.setState({
                isVisible
            });

            this.props.onChange && this.props.onChange(isVisible);
        }
    }

    getContainer() {
        return this.props.containment || window;
    }

    public render() {
        const {
            active,
            canLoadMore,
            className,
            showLoader,
            children
        } = this.props;

        if (!canLoadMore) {
            return null;
        }

        return (
            <div className={className ?? ''}>
                {(canLoadMore || active) &&
                    (showLoader ?
                        <div style={{ justifyContent: 'center', display: 'flex', margin: '5px', maxHeight: '40px', minHeight: '40px' }}>
                            <Spin size="large" />
                        </div> :
                        children)}
            </div>
        );
    }
}

export default LoadMoreTrigger;
