import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BootstrapActions from '../actions/bootstrapActions';
import { BootstrapViewModel } from '../api/models/BootstrapViewModel';
import Login from '../components/Login';
import { ErrorState } from '../reducers/error';
import Notifier from './Notifier';
import { ApplicationState } from './configureStore';

interface AppProps {
    children?: React.ReactNode;
    bootstrap?: BootstrapViewModel | undefined;
    accessToken?: string | undefined | null;
    isLoggedIn?: boolean;
    error?: ErrorState | undefined;
};

function Nav(path: string) {
    const navigate = useNavigate();
    navigate(path);
}

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "1px",
                    marginBottom: "1px",
                    backgroundColor: "#d5d5d5",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "#c5c5c5",
                    },
                },
            }
        }
    }
});


class App extends React.Component<AppProps> {
    constructor(props: AppProps) {
        super(props);

        if (this.props.accessToken && !(this.props.bootstrap && this.props.bootstrap.user))
            BootstrapActions.get();
    }

    componentDidUpdate(nextProps: AppProps) {
        if ((nextProps.accessToken && (nextProps.accessToken != this.props.accessToken)) ||
            (this.props.accessToken && !(this.props.bootstrap && this.props.bootstrap.user)))
            BootstrapActions.get();

        //const navigate = useNavigate();

        const recvd403Error = nextProps.error?.has403Error && !this.props.error?.has403Error;

        if (recvd403Error && window.location.pathname !== '/403') {
            Nav(`/403?conflictURL=${window.location.pathname}`);
        }

        const recvd404Error = nextProps.error?.has404Error && !this.props.error?.has404Error;

        if (recvd404Error && window.location.pathname !== '/404') {
            Nav('/404');
        }
    }

    render() {
        const { children, isLoggedIn } = this.props;
        
        return (
            <Fragment>
                <SnackbarProvider maxSnack={3}>
                    <ThemeProvider theme={theme}>
                        <Notifier />
                        {
                            isLoggedIn ?
                                (<div>{children}</div>) :
                                (
                                    <div style={{ margin: 'auto' }}>
                                        <Login />
                                    </div>
                                )
                        }
                    </ThemeProvider>
                </SnackbarProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState): any => {
    const { bootstrap, error, login } = state;
    return {
        bootstrap,
        error,
        isLoggedIn: !!login && !!login.accessToken,
        accessToken: login && login.accessToken,
    };
};

export default connect(mapStateToProps)(App);
