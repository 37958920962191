import { CopyOutlined, DeleteOutlined, ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Input, Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { connect } from 'react-redux';
import sessionActions from '../actions/sessionActions';
import { SessionViewModel } from '../api/models/SessionViewModel';
import { TelemetrySessionStatus } from '../api/models/TelemetrySessionStatus';
import { UserAllowedOptionsViewModel } from '../api/models/UserAllowedOptionsViewModel';
import { ApplicationState } from '../app/configureStore';
import '../icons.css';
import { formatDate } from '../utils/dateUtils';
import i18nUtils from '../utils/i18nUtils';
import LoadMoreTrigger from '../utils/loadMoreTrigger';
import { makeDistinct } from '../utils/utils';
import './sessions.css';

const _i = i18nUtils.translationFactory('Sessions.List');
const _is = i18nUtils.translationFactory('Enums.TelemetrySessionStatus');

const PageSize = 30;
const { Search } = Input;

type SessionsProps = {
    sessions: SessionViewModel[] | null | undefined;
    pageNumber: number | null;
    userOptions: UserAllowedOptionsViewModel | undefined;
}

interface SessionsParamsProps {
};

type FullSessionsProps = SessionsProps & SessionsParamsProps;

type State = {
    currentSession: SessionViewModel | null;
    isDeleteAllConfirmationOpen: boolean;
    isDeleteConfirmationOpenForId: number | null;
    isResetConfirmationOpenForId: number | null;
    isNewSessionDrawerOpen: boolean;
    pageNumber: number | null;
    searchValue: string;
}

export class Sessions extends React.Component<FullSessionsProps, State> {
    constructor(props: FullSessionsProps) {
        super(props);

        this.state = {
            currentSession: null,
            isDeleteAllConfirmationOpen: false,
            isDeleteConfirmationOpenForId: null,
            isResetConfirmationOpenForId: null,
            isNewSessionDrawerOpen: false,
            pageNumber: null,
            searchValue: "",
        }
    }

    componentDidMount() {
        sessionActions.clear();
        this.setState({ searchValue: "" });
    }

    render() {
        const { isDeleteConfirmationOpenForId, isDeleteAllConfirmationOpen, isResetConfirmationOpenForId, searchValue } = this.state;
        const { sessions, userOptions } = this.props;

        const canLoadMore = this.computeCanLoadMore();
        const active = this.computeActive();

        const distinct = sessions ? makeDistinct(sessions, p => p.id ?? 0) : [];

        const columns: ColumnsType<SessionViewModel> = [
            {
                title: _i('IDsStatus'),
                key: 'idsStatus',
                render: (_, item) =>
                    <Descriptions layout='horizontal' column={{ xl: 1 }} bordered size='small'>
                        <Descriptions.Item label={_i('GroupUUID')}>{item.groupUuid}</Descriptions.Item>
                        <Descriptions.Item label={_i('AdventureId')}>{item.adventureId}</Descriptions.Item>
                        <Descriptions.Item label={_i('SerialNumber')}>{item.serialNumber}</Descriptions.Item>
                        <Descriptions.Item label={_i('Status')}><div className={this.getColorClassName(item.status)}> {_is(item.status?.toString() ?? '')}</div></Descriptions.Item>
                    </Descriptions>
            },
            {
                title: _i('UserClientInfo'),
                key: 'userClientInfo',
                render: (_, item) =>
                    <Descriptions layout='horizontal' column={{ xl: 1 }} bordered size='small'>
                        <Descriptions.Item label={_i('UserEmail')}>{item.userEmail}</Descriptions.Item>
                        <Descriptions.Item label={_i('FirstName')}>{item.firstName}</Descriptions.Item>
                        <Descriptions.Item label={_i('LastName')}>{item.lastName}</Descriptions.Item>
                        <Descriptions.Item label={_i('Client')}>{item.creatorName}</Descriptions.Item>
                    </Descriptions>
            },
            {
                title: _i('Dates'),
                key: 'createdDate',
                render: (_, item) =>
                    <Descriptions layout='horizontal' column={{ xl: 1 }} bordered size='small'>
                        <Descriptions.Item label={_i('Timestamp')}>{formatDate(item.timestamp)}</Descriptions.Item>
                        <Descriptions.Item label={_i('OpenDate')}>{formatDate(item.createdDate)}</Descriptions.Item>
                        <Descriptions.Item label={_i('LastUpdateDate')}>{formatDate(item.lastModifiedDate)}</Descriptions.Item>
                    </Descriptions>
            },
            {
                title: _i('Actions'),
                key: 'actions',
                render: (_, item) =>
                    <Popover content=
                        {
                            <div style={{ margin: '10px' }}>
                                <div>
                                    {_i('DeleteSession')}
                                </div>
                                <div className="client-button-box">
                                    <Button onClick={() => this.deleteSession()} type="primary" >{_i('Yes')}</Button>
                                    <Button onClick={() => this.onDeleteSessionConfirmationClose()} danger>{_i('No')}</Button>
                                </div>
                            </div>
                        }
                        open={isDeleteConfirmationOpenForId == item.id} title="" trigger="click" onOpenChange={() => this.onDeleteSessionClick(item)} placement='bottom'>
                        <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popover>
                ,
                width: 60,
                fixed: 'right',
            },
        ];

        return (
            <div>
                <div className="sessions-title">{_i('Sessions')}</div>

                <div className="sessions-expand">
                    <Search size='small' placeholder={_i('SearchTitle')} onSearch={this.find} value={searchValue} onChange={this.onSearchChange} />
                    <Button onClick={() => this.request()} icon={<ReloadOutlined />}>{_i('Refresh')}</Button>

                    {
                        userOptions?.isAllowedToDeleteAllSessions ?
                            <Popover content=
                                {
                                    <div style={{ margin: '10px' }}>
                                        <div>
                                            {_i('DeleteSessions')}
                                        </div>
                                        <div className="client-button-box">
                                            <Button onClick={() => this.deleteSessions()} type="primary" >{_i('Yes')}</Button>
                                            <Button onClick={() => this.onSessionsConfirmationClose()} danger>{_i('No')}</Button>
                                        </div>
                                    </div>
                                }
                                open={isDeleteAllConfirmationOpen} title="" trigger="click" onOpenChange={() => this.onDeleteSessionsClick()} placement='bottom'>
                                <Button icon={<DeleteOutlined />}>{_i('DeleteAll')}</Button>
                            </Popover> : null
                    }

                </div>

                <Divider />
                {
                    sessions ?
                        <Table<SessionViewModel> columns={columns} dataSource={distinct} bordered pagination={false}
                            rowKey="id"
                            expandable={{
                                expandedRowRender: (record) => record.url ?
                                    (
                                        <div>
                                            <div className="sessions-expand">
                                                <Button shape="circle" icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(record.url ?? "")} />
                                                <p><a href={record.url} target="_blank" rel="noopener noreferrer">{record.url}</a></p>
                                            </div>
                                            {
                                                record.status == TelemetrySessionStatus.COMPLETED ?
                                                    <Popover content=
                                                        {
                                                            <div style={{ margin: '10px' }}>
                                                                <div>
                                                                    {_i('ResetStatus')}
                                                                </div>
                                                                <div className="client-button-box">
                                                                    <Button onClick={() => this.resetSessionStatus()} type="primary" >{_i('Yes')}</Button>
                                                                    <Button onClick={() => this.onResetSessionStatusConfirmationClose()} danger>{_i('No')}</Button>
                                                                </div>
                                                            </div>
                                                        }
                                                        open={isResetConfirmationOpenForId == record.id} title="" trigger="click" onOpenChange={() => this.onResetSessionStatusClick(record)} placement='bottom'>
                                                        <Button shape="circle" icon={<RollbackOutlined />} />
                                                    </Popover> : <div />
                                            }
                                        </div>
                                    ) :
                                    (<pre>{record.errorMessage}</pre>),
                                rowExpandable: (record) => !!(record.url || record.errorMessage),
                            }}
                        /> : null
                }

                <LoadMoreTrigger
                    onChange={this.onLoadMoreChange}
                    offset={{ bottom: -150 }}
                    canLoadMore={canLoadMore}
                    active={active}
                />
            </div>
        );
    }

    request = (reset: boolean = true) => {
        const { searchValue, pageNumber } = this.state;
        const newPageNumber = reset || pageNumber == null ? 0 : pageNumber + 1;

        this.setState({ pageNumber: newPageNumber });

        if (newPageNumber == 0)
            sessionActions.clear();

        sessionActions.getList(undefined, searchValue ? searchValue : undefined, PageSize, newPageNumber);
    }

    onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchValue: e.target.value });
    }

    find = (searchText: string) => {
        this.request();
    }

    getColorClassName(status: TelemetrySessionStatus | undefined) {
        switch (status) {
            case TelemetrySessionStatus.OPEN:
            case TelemetrySessionStatus.UPLOADED:
                return 'sessions-status-open';
            case TelemetrySessionStatus.READY_TO_PROCESS:
                return 'sessions-status-ready';
            case TelemetrySessionStatus.COMPLETED:
                return 'sessions-status-completed';
            case TelemetrySessionStatus.ERROR:
                return 'sessions-status-error';
        }
        return '';
    }

    deleteSession = () => {
        const session = this.state.currentSession;

        this.setState({ isDeleteConfirmationOpenForId: null, currentSession: null });

        if (session && session.id)
            sessionActions
                .delete(session.id)
                .then(() => this.request());
    }

    onDeleteSessionClick = (session: SessionViewModel) => {
        this.setState({ isDeleteConfirmationOpenForId: session.id ?? null, currentSession: session });
    };

    onDeleteSessionConfirmationClose = () => {
        this.setState({ isDeleteConfirmationOpenForId: null, currentSession: null });
    };

    resetSessionStatus = () => {
        const session = this.state.currentSession;

        this.setState({ isDeleteConfirmationOpenForId: null, currentSession: null });

        if (session && session.id)
            sessionActions
                .resetSessionStatus(session.id)
                .then(() => this.request());
    }

    onResetSessionStatusClick = (session: SessionViewModel) => {
        this.setState({ isResetConfirmationOpenForId: session.id ?? null, currentSession: session });
    };

    onResetSessionStatusConfirmationClose = () => {
        this.setState({ isResetConfirmationOpenForId: null, currentSession: null });
    };

    deleteSessions = () => {
        this.setState({ isDeleteAllConfirmationOpen: false });

        sessionActions
            .deleteAll()
            .then(() => this.request());
    }

    onDeleteSessionsClick = () => {
        this.setState({ isDeleteAllConfirmationOpen: true });
    };

    onSessionsConfirmationClose = () => {
        this.setState({ isDeleteAllConfirmationOpen: false });
    };

    computeCanLoadMore = () => {
        const { searchValue, pageNumber } = this.state;
        const { pageNumber: requestedPageNumber, sessions } = this.props;

        // Do not paginate if no search performed
        if ((!searchValue == null || searchValue.trim() === '') && pageNumber != null)
            return false;

        return (pageNumber == null ||
            !sessions ||
            (requestedPageNumber ?? 0) < pageNumber ||
            requestedPageNumber == pageNumber && (sessions?.length ?? 0) >= (1 + pageNumber) * PageSize);
    }

    computeActive = () => {
        const { pageNumber } = this.state;
        const { pageNumber: requestedPageNumber, sessions } = this.props;

        return pageNumber == null || requestedPageNumber != null && (requestedPageNumber == pageNumber && (sessions?.length ?? 0) >= (1 + pageNumber) * PageSize);
    }

    onLoadMoreChange = (isVisible: boolean) => {
        const canLoadMore = this.computeCanLoadMore();
        const active = this.computeActive();

        if (isVisible) {
            if (canLoadMore && active) {
                this.request(false);
            }
        }
    }
}

const mapStateToProps = (state: ApplicationState): SessionsProps => {
    const { session, bootstrap } = state;
    return {
        sessions: session?.list,
        pageNumber: session?.pageNumber ?? null,
        userOptions: bootstrap?.userAllowedOptions,
    };
};

export default connect(mapStateToProps)(Sessions);