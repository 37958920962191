import { Dispatch, Middleware, Store } from 'redux';
import { reduxStore } from '../app/configureStore';
import { OnLoggedInAction } from '../reducers/login';

class LocalStorage {
    onStart = () => {
        // Load token from local storage
        try {
            const accessToken = localStorage.getItem('access_token');
            if (accessToken && accessToken.length > 20)
                reduxStore.dispatch({ type: 'ON_LOGGEDIN', accessToken });
        }
        catch (e) {
        }
    }

    // Define middleware to store token to local storage
    storageMiddleware: Middleware<{}, any> = (api) => (next) => (action) => {
        if (action.type === 'ON_LOGGEDIN') {
            if (localStorage.getItem('access_token') !== action.accessToken) {
                localStorage.setItem('access_token', action.accessToken);
            }
        } else if (action.type === 'ON_LOGOUT') {
            localStorage.removeItem('access_token');
        }
        return next(action);
    }
};

let actions = new LocalStorage();

export default actions;
