import api from '../api';
import { reduxStore } from '../app/configureStore';
import requestUtils from '../utils/requestUtils';

class BootstrapActions {
    get() {
        requestUtils.processRequest(api.bootstrap.getBoostrapData(), 'ON_BOOTSTRAP');
    }

    clear() {
        reduxStore.dispatch({ type: 'ON_CLEAR_BOOTSTRAP' });
    }
};

const actions = new BootstrapActions();

export default actions;
