import * as React from 'react';
import { Route, Routes } from 'react-router';
import Clients from '../components/Clients';
import Firmwares from '../components/Firmwares';
import Home from '../components/Home';
import Layout from '../components/Layout';
import Sessions from '../components/Sessions';
import App from './App';

export default () => (
    <App>
        <Layout>
            <Routes>
                <Route path='/about' element={<Home />} />
                <Route path='/' element={<Clients />} />
                <Route path='/session' element={<Sessions />} />
                <Route path='/firmware' element={<Firmwares />} />
            </Routes>
        </Layout>
    </App>
);
