import { reduxStore } from '../app/configureStore';

class LoginActions {
    loggedIn(accessToken: string) {
        reduxStore.dispatch({ type: 'ON_LOGGEDIN', accessToken });
    }

    logout() {
        reduxStore.dispatch({
            type: 'ON_LOGOUT'
        });
    }
};

const actions = new LoginActions();
export default actions;
