import * as React from 'react';

interface GlyphIconProps {
    className: string;
}

export function GlyphIcon(props: GlyphIconProps) {
    return (
        <span><span className={`glyphicon ${props.className}`} /></span>
    );
}
