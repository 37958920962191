import { OpenAPI } from './api/core/OpenAPI';
import { reduxStore } from './app/configureStore';
import { BlackburnClient } from './api/BlackburnClient';

OpenAPI.TOKEN = async () => {
    return String(reduxStore.getState().login.accessToken)
};

OpenAPI.HEADERS = {
    'Content-Type': 'application/json',
    'X-Referrer': window.location.href,
};

const api = new BlackburnClient(OpenAPI);

export default api;