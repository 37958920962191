import { Reducer } from 'redux';
import { ClientViewModel } from '../api/models/ClientViewModel';
import { AccessTokenViewModel } from '../api/models/AccessTokenViewModel';

export type ClientState = {
    list?: ClientViewModel[] | null;
    created: ClientViewModel | null;
    tokens: AccessTokenViewModel[];
};

export const ClientDefaultState: ClientState = {
    list: null,
    created: null,
    tokens: [],
};

export interface OnClientGetListAction {
    type: 'ON_GET_CLIENT_LIST';
    result: ClientViewModel[];
}

export interface OnClearClientAction {
    type: 'ON_CLEAR_CLIENT_LIST';
}

export interface OnClientCreateAction {
    type: 'ON_CREATE_CLIENT';
    result: ClientViewModel;
}

export interface OnGetTokenAction {
    type: 'ON_GET_CLIENT_TOKEN';
    result: ClientViewModel;
}

type Actions = OnClientGetListAction | OnClearClientAction | OnClientCreateAction | OnGetTokenAction;

export const client: Reducer<ClientState, Actions> = (state: ClientState | undefined, action: Actions): ClientState => {
    if (state === undefined) {
        return ClientDefaultState;
    }

    switch (action.type) {
        case 'ON_GET_CLIENT_LIST':
            return Object.assign({}, state, { list: action.result });
        case 'ON_CLEAR_CLIENT_LIST':
            return Object.assign({}, state, { list: null });
        case 'ON_CREATE_CLIENT':
            return Object.assign({}, state, { created: action.result });
        case 'ON_GET_CLIENT_TOKEN':
            return Object.assign({}, state, { tokens: [...state.tokens, action.result] });
        default:
            return state;
    }
}

