/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TelemetrySessionStatus {
    NONE = 'None',
    OPEN = 'Open',
    UPLOADED = 'Uploaded',
    ASSEMBLED = 'Assembled',
    READY_TO_PROCESS = 'ReadyToProcess',
    COMPLETED = 'Completed',
    ERROR = 'Error',
}
