
export function makeDistinct<T>(array: T[], selector: (instance: T) => number) {
    var unique = [];
    var distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[selector(array[i])]) {
            distinct.push(array[i]);
            unique[selector(array[i])] = 1;
        }
    }

    return distinct;
}