import { Button, Dialog, Drawer, IconButton, Popover, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import firmwareActions from '../actions/firmwareActions';
import { FirmwareViewModel } from '../api/models/FirmwareViewModel';
import { ApplicationState } from '../app/configureStore';
import '../icons.css';
import { GlyphIcon } from '../utils/GlyphIcon';
import i18nUtils from '../utils/i18nUtils';
import NewFirmware from './NewFirmware';
import './firmwares.css';

const _i = i18nUtils.translationFactory('Firmwares.List');

type FirmwaresProps = {
    firmwares: FirmwareViewModel[] | null | undefined;
    url: string | null | undefined;
}

interface FirmwaresParamsProps {
};

type FullFirmwaresProps = FirmwaresProps & FirmwaresParamsProps;

type State = {
    isNewFirmwareDrawerOpen: boolean;
    confirmationAnchor: HTMLElement | null;
    currentFirmware: FirmwareViewModel | null;
}

export class Firmwares extends React.Component<FullFirmwaresProps, State> {
    constructor(props: FullFirmwaresProps) {
        super(props);

        this.state = {
            isNewFirmwareDrawerOpen: false,
            confirmationAnchor: null,
            currentFirmware: null,
        }
    }

    componentDidMount() {
        firmwareActions.clear();
        this.request();
    }

    render() {
        const { firmwares, url } = this.props;
        const { isNewFirmwareDrawerOpen, confirmationAnchor } = this.state;

        if (!firmwares)
            return null;

        return (
            <div>
                <div className="firmwares-title">{_i('Firmwares')}</div>

                <Button onClick={() => this.openAddFirmwareDrawer()} variant="outlined"><GlyphIcon className='glyphicon-plus' />{_i('AddFirmware')}</Button>

                <div className="firmwares-list">
                    {_.map(firmwares, (item, index) => (
                        <div key={item.id} className="firmware-item-box">
                            <IconButton onClick={() => this.generateUrl(item)}><span className="glyphicon glyphicon-link" /></IconButton>
                            <div className="firmwares-item-version">
                                {item.version}
                            </div>
                            <div className="firmwares-item-description">
                                {item.description}
                            </div>
                            <IconButton className="last-item" onClick={(e) => this.onDeleteClick(e, item)}><span className="glyphicon glyphicon-trash" /></IconButton>
                        </div>
                    ))}
                </div>

                <Popover
                    open={!!confirmationAnchor}
                    anchorEl={confirmationAnchor}
                    onClose={this.onConfirmationClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ margin: '10px' }}>
                        <div>
                            {_i('DeleteQuestion')}
                        </div>
                        <div className="firmware-button-box">
                            <Button onClick={() => this.delete()} variant="contained" color="success" >{_i('Yes')}</Button>
                            <Button onClick={() => this.onConfirmationClose()} variant="outlined" color="error">{_i('No')}</Button>
                        </div>

                    </div>
                </Popover>

                <Drawer anchor="right" open={isNewFirmwareDrawerOpen} onClose={this.onNewClose} >
                    <NewFirmware onAddItem={this.onAddItem} />
                </Drawer>

                <Dialog open={!!url} onClose={this.onLinkClose} style={{ minWidth: '600px' }} >
                    <div className="firmware-url-box">
                        <TextField variant='outlined' size='small' defaultValue={url} label={_i('UrlTitle')} aria-readonly style={{ minWidth: '500px' }} />
                        <IconButton className="last-item" onClick={() => this.onCopyClick()}><span className="glyphicon glyphicon-file" /></IconButton>
                    </div>
                </Dialog>

            </div>
        );
    }

    generateUrl = (firmware: FirmwareViewModel) => {
        if (firmware.id)
            firmwareActions.getUrl(firmware.id);
    }

    delete = () => {
        const firmware = this.state.currentFirmware;

        this.setState({ confirmationAnchor: null, currentFirmware: null });

        if (firmware && firmware.id)
            firmwareActions
                .delete(firmware.id)
                .then(() => firmwareActions.getList());
    }

    onCopyClick = () => {
        navigator.clipboard.writeText(this.props.url ?? "");
    }

    onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, firmware: FirmwareViewModel) => {
        this.setState({ confirmationAnchor: event.currentTarget, currentFirmware: firmware });
    };

    onConfirmationClose = () => {
        this.setState({ confirmationAnchor: null, currentFirmware: null });
    };

    request = () => {
        firmwareActions.getList();
    }

    onAddItem = () => {
        this.setState({ isNewFirmwareDrawerOpen: false });
        this.request();
    }

    openAddFirmwareDrawer = () => {
        this.setState({ isNewFirmwareDrawerOpen: true });
    }

    onNewClose = () => {
        this.setState({ isNewFirmwareDrawerOpen: false });
    }

    onLinkClose = () => {
        firmwareActions.clearUrl();
    }
}

const mapStateToProps = (state: ApplicationState): FirmwaresProps => {
    const { firmware } = state;
    return {
        firmwares: firmware?.list,
        url: firmware?.url,
    };
};


export default connect(mapStateToProps)(Firmwares);