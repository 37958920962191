import * as _ from 'lodash';
import NotifierActions from '../actions/notifierActions';
import type { CancelablePromise } from '../api/core/CancelablePromise';
import { reduxStore } from '../app/configureStore';
import i18nUtils from './i18nUtils';

const _i = i18nUtils.translationFactory('Errors');

class RequestUtils {

    processRequest<T>(request: CancelablePromise<T>, actionType: string, args?: object) {
        return request
            .then(res => {
                const state = {
                    type: actionType,
                    result: res,
                    ...args
                }
                reduxStore.dispatch(state);
            })
            .catch(res => {
                this.handleError(res);
            });
    }

    simpleRequest<T>(request: CancelablePromise<T>) {
        return request
            .catch(res => {
                this.handleError(res);
            });
    }

    logout = () => {
        reduxStore.dispatch({
            type: 'ON_LOGOUT'
        });
    }

    handleError = (response: any) => {

        if (typeof response !== 'undefined') {

            // Custom Redirects
            if (response.status === 401) {
                // If access_token has expired, then redirect user to login
                console.log('Recv\'d 401 Unauthorized response from API'); // eslint-disable-line
                this.logout();
            } else {
                // Handle remaining 400s and 500s
                const responseLevel = Math.floor(response.status / 100);

                // If we have a response level
                if (responseLevel > 0) {

                    // Redirect to Sad Dog Page on 403 Forbidden responses
                    if (response.status === 403) {
                        reduxStore.dispatch({ type: 'ON_403_FORBIDDEN_RECVD' });
                        return; // we really DON'T need error banners for 403s since we're on an error page!
                    }

                    let responseMessage = null;
                    let cmd = false;

                    if (response.body) {
                        const errors = response.body.errorMessages as string[];
                        if (errors) {
                            responseMessage = errors.join('\n');
                        }
                    }

                    const msg = cmd
                        ? `${_i('Response')} : ${responseMessage}`
                        : responseMessage;

                    if (responseLevel === 5)
                        NotifierActions.addError(msg ?? "");
                    else
                        NotifierActions.addWarning(msg ?? "");
                } else {
                    console.log('We just got an error message from the API without a Status Code.'); // eslint-disable-line
                }
                console.log(response); // eslint-disable-line
            }
        } else {
            console.log('Non-XHR error caught. Response was undefined.'); // eslint-disable-line
            console.log(response); // eslint-disable-line
        }
    }
}

const requestUtils = new RequestUtils();

export default requestUtils;
