import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import LocalStorage from '../data/localStorage';
import { BootstrapDefaultState, BootstrapState, bootstrap } from '../reducers/bootstrap';
import { ClientDefaultState, ClientState, client } from '../reducers/client';
import { ErrorDefaultState, ErrorState, error } from '../reducers/error';
import { FirmwareDefaultState, FirmwareState, firmware } from '../reducers/firmware';
import { LoginDefaultState, LoginState, login } from '../reducers/login';
import { NotifierDefaultState, NotifierState, notifier } from '../reducers/notifier';
import { SessionDefaultState, SessionState, session } from '../reducers/session';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory();

const enhancers = [];
const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

export interface ApplicationState {
    bootstrap: BootstrapState | undefined;
    client: ClientState | undefined;
    error: ErrorState | undefined;
    firmware: FirmwareState | undefined;
    login: LoginState | undefined;
    notifier: NotifierState | undefined;
    session: SessionState | undefined;
}

const initialState: ApplicationState = {
    bootstrap: BootstrapDefaultState,
    client: ClientDefaultState,
    error: ErrorDefaultState,
    firmware: FirmwareDefaultState,
    login: LoginDefaultState,
    notifier: NotifierDefaultState,
    session: SessionDefaultState,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface AppThunkFunction<TAction, TReturn> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): TReturn;
}

const middleware = [
    thunk,
    routerMiddleware(history),
    LocalStorage.storageMiddleware,
];

export const reduxStore = configureStore({
    reducer: combineReducers({
        bootstrap,
        client,
        error,
        firmware,
        login,
        notifier,
        session,
        routing: routerReducer,
    }),
    middleware: middleware,
    enhancers: enhancers,
    preloadedState: initialState,
})