/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientIdViewModel } from '../models/ClientIdViewModel';
import type { ClientViewModel } from '../models/ClientViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public addClient(
requestBody?: ClientIdViewModel,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param pageSize Page Size
     * @param pageNumber Page Number
     * @returns ClientViewModel Success
     * @throws ApiError
     */
    public getClients(
pageSize?: number,
pageNumber?: number,
): CancelablePromise<Array<ClientViewModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/client',
            query: {
                'PageSize': pageSize,
                'PageNumber': pageNumber,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public removeClient(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/client/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public getTokenForProcessor(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/client/{id}/token',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

}
