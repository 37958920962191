import * as _ from 'lodash';
import { EventEmitter } from 'events';

const CHANGE_EVENT = 'change';

type Language = {
    lcid: string;
    messages: object;
};

class i18nMessagesStoreClass extends EventEmitter {

    _lcid: string;
    _languages: Language[];
    _messages: object;


    constructor() {
        super();

        this._lcid = 'en-us';
        this._messages = {};

        this._languages = [
            {
                lcid: 'en-us',
                messages: {
                    Errors: {
                        '403': '403',
                        '404': '404',
                        '500': '500',
                        ApplicationError: 'Application Error',
                        ContactSupport: 'If you need further assistance, contact Support.',
                        ForbiddenPage: 'You do not have access to this page',
                        Message: 'Message',
                        Msg404: 'The page you blah blah blah yeah it�s not here.',
                        NoKeys: 'You do not have keys to unlock this door.',
                        PageNotFound: 'Page Not Found',
                        PermissionRequired: 'Permission Required',
                        Response: 'Response',
                        StatusCode: 'Status Code',
                        UseNav: 'Please use the navigation to find the page you are looking for.',
                        ValidationErrors: 'Request is invalid:'
                    },
                    Menu: {
                        Title: 'Milo Telemetry',
                        About: 'About',
                        Firmware: 'Firmware',
                        Client: 'Client',
                        Session: 'Session',
                    },
                    UserPanel: {
                        Initializing: 'Initializing...',
                        Welcome: 'Welcome',
                        Logout: 'Logout',
                        Settings: 'Settings',
                    },
                    Login:
                    {
                        Signin: 'Sign in to Milo Telemetry',
                        Failed: 'Login failed',
                    },
                    Firmwares: {
                        List: {
                            Firmwares: 'Firmwares',
                            AddFirmware: 'Add Firmware',
                            UrlTitle: 'Temporary URL to the firmware',
                            Yes: 'Yes',
                            No: 'No',
                            DeleteQuestion: 'Are you sure to delete the firmware?',
                        },
                        New: {
                            NewFirmware: 'New Firmware',
                            VersionTitle: 'Firmware Version',
                            DescriptionTitle: 'Firmware Description',
                            AddFirmware: 'Add Firmware',
                            UploadFirmwareImage: 'Select Firmware Image',
                        },
                    },
                    Clients: {
                        List: {
                            Clients: 'Clients',
                            AddClient: 'Add Client',
                            Yes: 'Yes',
                            No: 'No',
                            DeleteQuestion: 'Are you sure to delete the client?',
                            Id: 'ID',
                            Type: 'Clent Type',
                            Actions: 'Actions',
                            NewClient: 'New Client',
                        },
                        New: {
                            IdTitle: 'Client ID',
                            SecretTitle: 'Client Secret',
                            AddClient: 'Add Client',
                            ClientType: 'Client Type',
                        },
                    },
                    Sessions: {
                        List: {
                            Session: 'Session',
                            Client: 'Client',
                            Status: 'Status',
                            Dates: 'Dates',
                            OpenDate: 'Open Date',
                            LastUpdateDate: 'Last Update Date',
                            Timestamp: 'Timestamp',
                            Token: 'Token',
                            FirstName: 'First Name',
                            LastName: 'Last Name',
                            GroupUUID: 'Group UUID',
                            IDsStatus: 'IDs & Status',
                            AdventureId: 'Adventure ID',
                            UserClientInfo: 'User & Client',
                            Yes: 'Yes',
                            No: 'No',
                            SerialNumber: 'Serial #',
                            UserEmail: 'User Email',
                            Actions: 'Actions',
                            Refresh: 'Refresh',
                            DeleteAll: 'Delete All',
                            DeleteSession: 'Are you sure to delete the session?',
                            DeleteSessions: 'Are you sure to delete ALL sessions?',
                            SearchTitle: 'Search by Serial Number',
                            ResetStatus: 'Are you sure to reset status?',
                        },
                    },
                    Enums: {
                        TelemetrySessionStatus: {
                            None: 'None',
                            Open: 'Open',
                            Uploaded: 'Uploaded',
                            Assembled: 'Assembled',
                            ReadyToProcess: 'Ready to Process',
                            Completed: 'Completed',
                            Error: 'Error',
                        },
                        UserType: {
                            Person: 'Person',
                            System: 'System',
                            Device: 'Device',
                            DataProcessor: 'Data Processor',
                        }
                    }
                }
            }
        ];

        this.updateMessagesWithEnglishFallback('en-us');
    }

    updateLCID(lcid: string) {
        this._lcid = lcid;
    }

    addLanguage(language: Language) {
        this._languages.push(language);
    }

    getDefaultLanguage() {
        return this._languages[0];
    }

    updateMessagesWithEnglishFallback(lcid: string) {
        if (typeof lcid === 'undefined') { return; }

        const currentLanguage = _.chain(this._languages)
            .filter(language => {
                return language.lcid === lcid;
            })
            .head()
            .value();

        if (typeof currentLanguage === 'undefined') { return; }

        _.merge(this._messages, this._languages[0].messages, currentLanguage.messages);
    }

    getMessages() {
        return this._messages;
    }

    getLCID() {
        return this._lcid;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback: (...args: any[]) => void) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback: (...args: any[]) => void) {
        this.removeListener(CHANGE_EVENT, callback);
    }
}

/*
const dispatcherCallback = payload => {
    let lcid = null;

    switch (payload.actionType) {
        case BootstrapConstants.GET_BOOTSTRAP:
            if (!payload.data.userPreference) { break; }

            lcid = payload.data.userPreference.localeLCID;
            i18nMessagesStore.updateLCID(lcid);
            i18nMessagesStore.emitChange();
            break;

        case UserPreferenceConstants.USER_PREFERENCE_INSERT_COMPLETED:

            lcid = payload.preference.localeLCID;
            i18nMessagesStore.updateMessagesWithEnglishFallback(lcid);
            i18nMessagesStore.updateLCID(lcid);
            i18nMessagesStore.emitChange();
            break;


        default:
        // no op
    }
};

AppDispatcher.register(dispatcherCallback);
*/

let i18nMessagesStore = new i18nMessagesStoreClass(); // eslint-disable-line
export default i18nMessagesStore;
