import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import ClientActions from '../actions/clientActions';
import { ClientIdViewModel } from '../api/models/ClientIdViewModel';
import { UserType } from '../api/models/UserType';
import { ApplicationState } from '../app/configureStore';
import i18nUtils from '../utils/i18nUtils';
import './newclient.css';

const _i = i18nUtils.translationFactory('Clients.New');
const _is = i18nUtils.translationFactory('Enums.UserType');

const _clientIdRegex = new RegExp('^[\\w\\.]{3,30}$');
const _passwordRegex = new RegExp('^(?=.*[\\d])(?=.*[\\W_])(?=.*[a-z])(?=.*[A-Z])[\\w\\d\\W]{6,64}$');

type NewClientProps = {
    onAddItem: () => void;
}

type State = {
    clientId: string;
    clientSecret: string;
    userType: UserType;
    isActionPerformed: boolean;
};

export class NewClient extends React.Component<NewClientProps, State> {
    constructor(props: NewClientProps) {
        super(props);

        this.state = {
            clientId: '',
            clientSecret: '',
            userType: UserType.DEVICE,
            isActionPerformed: false,
        }
    }

    componentDidMount() {
        this.setState({
            clientId: '',
            clientSecret: '',
            userType: UserType.DEVICE,
            isActionPerformed: false,
        });
    }

    render() {
        const { clientId, clientSecret, userType, isActionPerformed } = this.state;

        return (
            <div>
                <div className='client-form'>
                    <div>
                        <Select
                            className='input-field-title'
                            defaultValue={UserType.DEVICE}
                            value={userType}
                            onChange={this.setUserType}
                            options={[
                                { value: UserType.DEVICE, label: _is(UserType.DEVICE) },
                                { value: UserType.DATA_PROCESSOR, label: _is(UserType.DATA_PROCESSOR) },
                            ]}
                        />
                    </div>

                    <div>
                        <Input addonBefore={_i('IdTitle')} value={clientId ?? ''} onChange={this.setIdText} className='input-field-title' />
                    </div>

                    <div>
                        <Input.Password addonBefore={_i('SecretTitle')} value={clientSecret ?? ''} onChange={this.setSecretText} className='input-field-title' disabled={userType == UserType.DATA_PROCESSOR} />
                    </div>

                    <div>
                        <Button onClick={() => this.request()} disabled={this.isActionDisabled()} loading={isActionPerformed} icon={<PlusSquareOutlined />}>{_i('AddClient')}</Button>
                    </div>
                </div>
            </div>
        );
    }

    setIdText = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ clientId: e.target.value });
    }

    setSecretText = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ clientSecret: e.target.value });
    }

    setUserType = (value: UserType) => {
        this.setState({ userType: value });
    };

    isActionDisabled = () => {
        const { clientId, clientSecret, userType } = this.state;

        if (!clientId)
            return true;
        if (!_clientIdRegex.test(clientId))
            return true;

        if (userType == UserType.DATA_PROCESSOR)
            return false;

        if (!clientSecret || !_passwordRegex.test(clientSecret))
            return true;

        return false;
    };

    request = () => {
        const { clientId, clientSecret, userType } = this.state;
        const { onAddItem } = this.props;

        this.setState({ isActionPerformed: true });

        const client: ClientIdViewModel = {
            clientId,
            clientSecret,
            userType,
        };

        ClientActions.create(client)
            .then(() => {
                onAddItem();
                this.setState({
                    clientId: '',
                    clientSecret: '',
                    userType: UserType.DEVICE,
                    isActionPerformed: false,
                });
            });
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const { } = state;
    return {
    };
};

export default connect(mapStateToProps)(NewClient);