/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SessionViewModel } from '../models/SessionViewModel';
import type { TelemetrySessionStatus } from '../models/TelemetrySessionStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SessionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param statuses 
     * @param serialNumber 
     * @param pageSize Page Size
     * @param pageNumber Page Number
     * @returns SessionViewModel Success
     * @throws ApiError
     */
    public getSessions(
statuses?: Array<TelemetrySessionStatus>,
serialNumber?: string,
pageSize?: number,
pageNumber?: number,
): CancelablePromise<Array<SessionViewModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/device-session',
            query: {
                'Statuses': statuses,
                'SerialNumber': serialNumber,
                'PageSize': pageSize,
                'PageNumber': pageNumber,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteAllSessions(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/device-session',
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public deleteSession(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/device-session/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public resetSessionStatus(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/device-session/{id}/reset-completed-status',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
