import { CopyOutlined, DeleteOutlined, LoginOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Input, Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { connect } from 'react-redux';
import clientActions from '../actions/clientActions';
import { ClientViewModel } from '../api/models/ClientViewModel';
import { UserType } from '../api/models/UserType';
import { ApplicationState } from '../app/configureStore';
import '../icons.css';
import i18nUtils from '../utils/i18nUtils';
import NewClient from './NewClient';
import './clients.css';

const _i = i18nUtils.translationFactory('Clients.List');
const _is = i18nUtils.translationFactory('Enums.UserType');

const PageSize = 30;

const { TextArea } = Input;

type ClientsProps = {
    clients: ClientViewModel[] | null | undefined;
    tokens: Map<number, string>;
}

interface ClientsParamsProps {
};

type FullClientsProps = ClientsProps & ClientsParamsProps;

type State = {
    isNewClientDrawerOpen: boolean;
    isDeleteConfirmationOpenForId: number | null;
    currentClient: ClientViewModel | null;
}


export class Clients extends React.Component<FullClientsProps, State> {
    constructor(props: FullClientsProps) {
        super(props);

        this.state = {
            isNewClientDrawerOpen: false,
            isDeleteConfirmationOpenForId: null,
            currentClient: null,
        }
    }

    componentDidMount() {
        clientActions.clear();
        this.request();
    }

    render() {
        const { clients, tokens } = this.props;
        const { isNewClientDrawerOpen, isDeleteConfirmationOpenForId } = this.state;

        const columns: ColumnsType<ClientViewModel> = [
            {
                title: _i('Id'),
                dataIndex: 'clientId',
                key: 'id',
                render: (text) => <b>{text}</b>,
            },
            {
                title: _i('Type'),
                dataIndex: 'userType',
                key: 'type',
                render: (text) => _is(text),
                width: 150,
            },
            {
                title: _i('Actions'),
                key: 'actions',
                render: (_, item) =>
                    <Popover content=
                        {
                            <div style={{ margin: '10px' }}>
                                <div>
                                    {_i('DeleteQuestion')}
                                </div>
                                <div className="client-button-box">
                                    <Button onClick={() => this.delete()} type="primary" >{_i('Yes')}</Button>
                                    <Button onClick={() => this.onConfirmationClose()} danger>{_i('No')}</Button>
                                </div>
                            </div>
                        }
                        open={isDeleteConfirmationOpenForId == item.id} title="" trigger="click" onOpenChange={() => this.onDeleteClick(item)} placement='bottom'>
                        <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popover>
                ,
                width: 60,
                fixed: 'right',
            },
        ];

        if (!clients)
            return null;

        return (
            <div>
                <div className="clients-title">{_i('Clients')}</div>

                <Button onClick={() => this.openAddClientDrawer()} icon={<PlusSquareOutlined />}>{_i('AddClient')}</Button>

                <Divider />

                <Table columns={columns} dataSource={clients} bordered pagination={false}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: (record) =>
                            <div className="clients-expand">
                                {
                                    tokens.get(record.id ?? -1) ?
                                        <Button shape="circle" icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(tokens.get(record.id ?? -1) ?? "")} /> :
                                        <Button shape="circle" icon={<LoginOutlined />} onClick={() => this.generateToken(record.id)} />
                                }
                                {
                                    tokens.get(record.id ?? -1) ?
                                        <TextArea
                                            value={tokens.get(record.id ?? -1) ?? ""}
                                            autoSize={{ minRows: 2, maxRows: 30 }}
                                            readOnly
                                        /> : null
                                }
                            </div>
                        ,
                        rowExpandable: (record) => record?.userType == UserType.DATA_PROCESSOR,
                    }}

                />

                <Drawer title={_i('NewClient')} placement="right" onClose={this.onNewClose} open={isNewClientDrawerOpen} size='large'>
                    <NewClient onAddItem={this.onAddItem} />
                </Drawer>
            </div>
        );
    }

    delete = () => {
        const client = this.state.currentClient;

        this.setState({ isDeleteConfirmationOpenForId: null, currentClient: null });

        if (client && client.id)
            clientActions
                .delete(client.id)
                .then(() => clientActions.getList(PageSize, 0));
    }

    onDeleteClick = (client: ClientViewModel) => {
        this.setState({ isDeleteConfirmationOpenForId: client.id ?? null, currentClient: client });
    };

    onConfirmationClose = () => {
        this.setState({ isDeleteConfirmationOpenForId: null, currentClient: null });
    };

    request = () => {
        clientActions.getList(PageSize, 0);
    }

    generateToken = (id: number | undefined) => {
        if (id && id > 0)
            clientActions.generateToken(id);
    }

    onAddItem = () => {
        this.setState({ isNewClientDrawerOpen: false });
        this.request();
    }

    openAddClientDrawer = () => {
        this.setState({ isNewClientDrawerOpen: true });
    }

    onNewClose = () => {
        this.setState({ isNewClientDrawerOpen: false });
    }
}

const mapStateToProps = (state: ApplicationState): ClientsProps => {
    const { client } = state;
    return {
        clients: client?.list,
        tokens: new Map(client?.tokens.map(obj => [obj.id ?? 0, obj.accessToken ?? ""]))
    };
};


export default connect(mapStateToProps)(Clients);