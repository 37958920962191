import { Reducer } from 'redux';
import { FirmwareViewModel } from '../api/models/FirmwareViewModel';
import { StringViewModel } from '../api/models/StringViewModel';


export type FirmwareState = {
    list?: FirmwareViewModel[] | null;
    url: string | null;
    created: FirmwareViewModel | null;
};

export const FirmwareDefaultState: FirmwareState = {
    list: null,
    url: null,
    created: null,
};

export interface OnFirmwareGetListAction {
    type: 'ON_GET_FIRMWARE_LIST';
    result: FirmwareViewModel[];
}

export interface OnClearFirmwareAction {
    type: 'ON_CLEAR_FIRMWARE_LIST';
}

export interface OnFirmwareGetUrlAction {
    type: 'ON_GET_FIRMWARE_URL';
    result: StringViewModel;
}

export interface OnClearFirmwareUrlAction {
    type: 'ON_CLEAR_FIRMWARE_URL';
}

export interface OnFirmwareCreateAction {
    type: 'ON_CREATE_FIRMWARE';
    result: FirmwareViewModel;
}

type Actions = OnFirmwareGetListAction | OnClearFirmwareAction | OnFirmwareGetUrlAction | OnFirmwareCreateAction | OnClearFirmwareUrlAction;

export const firmware: Reducer<FirmwareState, Actions> = (state: FirmwareState | undefined, action: Actions): FirmwareState => {
    if (state === undefined) {
        return FirmwareDefaultState;
    }

    switch (action.type) {
        case 'ON_GET_FIRMWARE_LIST':
            return Object.assign({}, state, { list: action.result });
        case 'ON_CLEAR_FIRMWARE_LIST':
            return Object.assign({}, state, { list: null });
        case 'ON_GET_FIRMWARE_URL':
            return Object.assign({}, state, { url: action.result.value });
        case 'ON_CLEAR_FIRMWARE_URL':
            return Object.assign({}, state, { url: null });
        case 'ON_CREATE_FIRMWARE':
            return Object.assign({}, state, { created: action.result });
        default:
            return state;
    }
}

