import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import LoginActions from '../actions/loginActions';
import { BootstrapViewModel } from '../api/models/BootstrapViewModel';
import { ApplicationState } from '../app/configureStore';
import '../icons.css';
import i18nUtils from '../utils/i18nUtils';
import './userPanel.css';

const _i = i18nUtils.translationFactory('UserPanel');

type UserPanelProps = {
    bootstrap: BootstrapViewModel | undefined;
    isLoggedIn: boolean;
};

type State = {
};

class UserPanel extends React.Component<UserPanelProps, State> {
    constructor(props: UserPanelProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { isLoggedIn, bootstrap } = this.props;

        const items: MenuProps['items'] = [
            {
                label: `${_i('Welcome')}, ${bootstrap?.user?.firstName}`,
                key: 'SubMenu',
                icon: <UserOutlined />,
                children: [
                    {
                        key: 'logout',
                        label: _i('Logout'),
                    },
                ],
            },
        ]

        return isLoggedIn && bootstrap && bootstrap.user ?
            (
                <div className="user-panel">
                    <Menu onClick={this.onClick} mode="horizontal" items={items} selectable={false} />
                </div>
            ) :
            (
                <div className="user-panel">
                    <div className="user-panel-box">
                        <div className="user-panel-text">{_i('Initializing')}</div>
                    </div>
                </div>
            );
    }

    onClick: MenuProps['onClick'] = (e) => {
        if (e.key == 'logout')
            LoginActions.logout();
    };
}

const mapStateToProps = (state: ApplicationState) => {
    const { bootstrap, login } = state;
    return {
        bootstrap,
        isLoggedIn: !!login && !!login.accessToken,
    };
};

export default connect(mapStateToProps)(UserPanel);
